<template>
  <div class="ud-body">
    <a-card :bordered="false">
      <a-form :label-col="labelCol" :model="where" :wrapper-col="wrapperCol">
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="真实姓名" name="realName">
              <a-input
                placeholder="请输入真实姓名"
                v-model:value="where.realName"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机号" name="phone">
              <a-input
                placeholder="请输入手机号"
                v-model:value="where.phone"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="身份证号" name="idcard">
              <a-input
                placeholder="请输入身份证号"
                v-model:value="where.idcard"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"><i class="iconfont icon-zaitu"></i>查询</a-button>
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
          </a-space></a-col>
        </a-row>
      </a-form>
      <ud-pro-table
        ref="table"
        :rowKey="(record) => record.phoneNumber"
        :columns="columns"
        :datasource="datasource"
        @change="handleChange"
        :scroll="{ x: 'max-content' }"
        bordered
      >
        <template #toolbar>
          <a-space style="margin: 0 20px">
            <a-button type="primary" v-show="false">
              <i class="iconfont icon-daochu" style="margin-right: 5px"></i>
              <span>导出</span>
            </a-button>
          </a-space>
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openDetail(record)">详情</a>
          </a-space>
        </template>
      </ud-pro-table>
      <real-name-detail
        v-model:visible="showDetail"
        :data="current || {}"
        @done="reload"
      />
    </a-card>
  </div>
</template>

<script>
import * as userRealApi from '@/api/sys/userReal'
import realNameDetail from './realNameDetail.vue'
export default {
  components: {
    realNameDetail
  },
  data() {
    return {
      labelCol: { md: { span: 6 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      form: {},
      rules: {},
      //   expand: false,
      isShowOperator: false,
      // 表格列配置
      columns: [
        {
          title: '序号',
          width: 48,
          align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '手机号',
          align: 'center',
          dataIndex: 'phone'
        },
        {
          title: '真实姓名',
          align: 'center',
          dataIndex: 'realName'
        },
        {
          title: '身份证号',
          align: 'center',
          dataIndex: 'idcard'
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'state'
        },
        {
          title: '结果',
          align: 'center',
          dataIndex: 'result'
        },
        {
          title: '操作',
          width: 200,
          dataIndex: 'action',
          align: 'center',
          slots: {
            customRender: 'action'
          }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格排序条件
      order: { sort: 'createTime', order: 'desc' },
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示详情弹窗
      showDetail: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false,
      // 是否显示用户导入弹窗
      showImport: false
    }
  },
  methods: {
    datasource(option, callback) {
      userRealApi
        .all({
          ...this.where,
          ...this.order,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            res.data.map((item) => {
              if (item.state === '0') {
                item.state = '审核中'
              } else if (item.state === '1') {
                item.state = '通过'
              } else if (item.state === '2') {
                item.state = '不通过'
              }
            })
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 表格分页、排序、筛选改变回调 */
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.order.sort = sorter.field
        this.order.order = { ascend: 'asc', descend: 'desc' }[sorter.order]
      } else {
        this.order = {}
      }
      // this.reload()
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    openEdit(row) {
      console.log(row)
      this.current = row
      this.showEdit = true
    },
    // 新增计费模板确定按钮回调事件
    handleBillingOk() {
      this.$refs.billingForm.validate().then((res) => {
        console.log(res)
        this.$message.success('添加计费模板成功！')
        this.visible = false
      })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() {}
  }
}
</script>
<style  scoped>
</style>
